:root {
    /*  palette */
    --color-white-main: white;
    --color-cyan-main: #06aed5;
    --color-cyan-superlight: #f9feff;
    --color-cyan-light: #c3f4ff;
    --color-cyan-lighter: #eafbff;
    --color-cyan-dark: #0a8ba9;
    --color-cyan-contrast: white;
    --color-purple-main: #665ff6;
    --color-purple-superlight: #fbfaff;
    --color-purple-light: #c2bfff;
    --color-purple-lighter: #e4e3ff;
    --color-purple-dark: #5650d0;
    --color-purple-contrast: white;
    --color-strong-pink-main: #ff007f;
    --color-strong-pink-superlight: #fff9fc;
    --color-strong-pink-light: #ffb2d8;
    --color-strong-pink-lighter: #ffe3f0;
    --color-strong-pink-dark: #be005f;
    --color-strong-pink-contrast: white;
    --color-light-blue-main: #455b9d;
    --color-light-blue-superlight: #fbfcff;
    --color-light-blue-light: #acb8d8;
    --color-light-blue-lighter: #e4e9f9;
    --color-light-blue-lighter2: #f4f6fc;
    --color-light-blue-dark: #303d5f;
    --color-light-blue-contrast: white;
    --color-grey-superlight: #fafafa;
    /* --color-steel-blue-main: #53596a; */
    --color-steel-blue-main: #3f4554;
    /* --color-steel-blue-main: #677087; */
    --color-steel-blue-light: #ebedf6;
    --color-green-main: #44cf98;
    --color-green-superlight: #f9fffa;
    --color-green-light: #ccffc3;
    --color-green-dark: #0aa934;
    --color-green-contrast: white;
    --color-green-lighter: #edf9f4;
    --color-yellow-main: #d59a06;
    --color-yellow-superlight: #fffcf9;
    --color-yellow-light: #ffe8c3;
    --color-yellow-lighter: #fff5ea;
    --color-yellow-dark: #a9640a;
    --color-yellow-contrast: white;

    /* general colors */
    --color-general-1-main: var(--color-purple-main);
    --color-general-1-light: var(--color-purple-light);
    --color-general-1-lighter: var(--color-purple-lighter);
    --color-general-1-superlight: var(--color-purple-superlight);
    --color-general-1-dark: var(--color-purple-dark);
    --color-general-1-contrast: var(--color-purple-contrast);
    --color-general-2-main: var(--color-light-blue-main);
    --color-general-2-light: var(--color-light-blue-light);
    --color-general-2-lighter: var(--color-light-blue-lighter);
    --color-general-2-lighter2: var(--color-light-blue-lighter2);
    --color-general-2-superlight: var(--color-light-blue-superlight);
    --color-general-2-dark: var(--color-light-blue-dark);
    --color-general-2-contrast: var(--color-light-blue-contrast);
    --color-general-3-main: var(--color-strong-pink-main);
    --color-general-3-light: var(--color-strong-pink-light);
    --color-general-3-lighter: var(--color-strong-pink-lighter);
    --color-general-3-superlight: var(--color-strong-pink-superlight);
    --color-general-3-dark: var(--color-strong-pink-dark);
    --color-general-3-contrast: var(--color-strong-pink-contrast);
    --color-general-4-main: var(--color-cyan-main);
    --color-general-4-light: var(--color-cyan-light);
    --color-general-4-lighter: var(--color-cyan-lighter);
    --color-general-4-superlight: var(--color-cyan-superlight);
    --color-general-4-dark: var(--color-cyan-dark);
    --color-general-4-contrast: var(--color-cyan-contrast);
    --color-general-5-main: var(--color-green-main);
    --color-general-5-lighter: var(--color-green-lighter);
    --color-general-5-light: var(--color-green-light);
    --color-general-5-superlight: var(--color-green-superlight);
    --color-general-5-dark: var(--color-green-dark);
    --color-general-5-contrast: var(--color-green-contrast);
    --color-general-6-main: var(--color-yellow-main);
    --color-general-6-light: var(--color-yellow-light);
    --color-general-6-lighter: var(--color-yellow-lighter);
    --color-general-6-superlight: var(--color-yellow-superlight);
    --color-general-6-dark: var(--color-yellow-dark);
    --color-general-6-contrast: var(--color-yellow-contrast);

    /* divider colors */
    --color-divider-1: var(--color-steel-blue-light);

    /* background color vars */
    --color-background-1: var(--color-grey-superlight);
    --color-background-2: var(--color-white-main);

    /* font color vars */
    --color-font-1: var(--color-steel-blue-main);
    --color-font-2: var(--color-white-main);
    --color-font-3: var(--color-purple-main);

    /* font size vars */
    --font-size-1: 8px;
    --font-size-2: 10px;
    --font-size-3: 12px;
    --font-size-4: 14px;
    --font-size-5: 14px;
    --font-size-6: 16px;
    --font-size-7: 20px;
    --font-size-8: 24px;
    --font-size-9: 30px;
    --font-size-10: 36px;

    /* spacing vars */
    --spacing-1: 5px;
    --spacing-2: 8px;
    --spacing-3: 10px;
    --spacing-4: 15px;
    --spacing-5: 20px;
    --spacing-6: 30px;
    --spacing-7: 40px;
    --spacing-8: 50px;
    --spacing-9: 70px;
    --spacing-10: 100px;

    /* button padding vars */
    --padding-button-1: 5px 16px;
    --padding-button-2: 12px 24px;
    --padding-button-3: 12px 30px;
    --padding-button-4: 12px 60px;
    --padding-button-5: 16px 80px;

    /* paper padding vars */
    --padding-paper-1: 8px;
    --padding-paper-2: 14px;
    --padding-paper-3: 30px;
    --padding-paper-4: 40px;
    --padding-paper-5: 56px;

    /* card padding vars */
    --padding-card-1: 10px;
    --padding-card-2: 14px;
    --padding-card-3: 20px;
    --padding-card-4: 24px;
    --padding-card-5: 30px;
    --padding-card-6: 36px;

    /* card color vars */
    --color-card-1-main: var(--color-cyan-main);
    --color-card-1-superlight: var(--color-cyan-superlight);
    --color-card-1-light: var(--color-cyan-light);
    --color-card-1-dark: var(--color-cyan-dark);
    --color-card-2-main: var(--color-purple-main);
    --color-card-2-superlight: var(--color-purple-superlight);
    --color-card-2-light: var(--color-purple-light);
    --color-card-2-dark: var(--color-purple-dark);
    --color-card-3-main: var(--color-strong-pink-main);
    --color-card-3-superlight: var(--color-strong-pink-superlight);
    --color-card-3-light: var(--color-strong-pink-light);
    --color-card-3-dark: var(--color-strong-pink-dark);
    --color-card-4-main: var(--color-light-blue-main);
    --color-card-4-superlight: var(--color-light-blue-superlight);
    --color-card-4-light: var(--color-light-blue-light);
    --color-card-4-dark: var(--color-light-blue-dark);

    /* list row color vars */
    --color-list-row-1-main: var(--color-cyan-main);
    --color-list-row-1-superlight: var(--color-cyan-superlight);
    --color-list-row-1-light: var(--color-cyan-light);
    --color-list-row-1-dark: var(--color-cyan-dark);
    --color-list-row-2-main: var(--color-purple-main);
    --color-list-row-2-superlight: var(--color-purple-superlight);
    --color-list-row-2-light: var(--color-purple-light);
    --color-list-row-2-dark: var(--color-purple-dark);
    --color-list-row-3-main: var(--color-strong-pink-main);
    --color-list-row-3-superlight: var(--color-strong-pink-superlight);
    --color-list-row-3-light: var(--color-strong-pink-light);
    --color-list-row-3-dark: var(--color-strong-pink-dark);
    --color-list-row-4-main: var(--color-light-blue-main);
    --color-list-row-4-superlight: var(--color-light-blue-superlight);
    --color-list-row-4-light: var(--color-light-blue-light);
    --color-list-row-4-dark: var(--color-light-blue-dark);

    /* icon color vars */
    --color-icon-1-main: var(--color-cyan-main);
    --color-icon-1-superlight: var(--color-cyan-superlight);
    --color-icon-1-light: var(--color-cyan-light);
    --color-icon-1-dark: var(--color-cyan-dark);
    --color-icon-2-main: var(--color-purple-main);
    --color-icon-2-superlight: var(--color-purple-superlight);
    --color-icon-2-light: var(--color-purple-light);
    --color-icon-2-dark: var(--color-purple-dark);
    --color-icon-3-main: var(--color-strong-pink-main);
    --color-icon-3-superlight: var(--color-strong-pink-superlight);
    --color-icon-3-light: var(--color-strong-pink-light);
    --color-icon-3-dark: var(--color-strong-pink-dark);
    --color-icon-4-main: var(--color-light-blue-main);
    --color-icon-4-superlight: var(--color-light-blue-superlight);
    --color-icon-4-light: var(--color-light-blue-light);
    --color-icon-4-dark: var(--color-light-blue-dark);

    /* file preview color vars */
    --color-file-preview-1: var(--color-light-blue-superlight);
    --color-icon-file-preview-1: var(--color-light-blue-main);
}
