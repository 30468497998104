@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;800;900&display=swap'); */

* {
	margin: 0;
	padding: 0;
}

*,
::before,
::after {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

body {
	font-family: 'Poppins', sans-serif;
}

button {
	cursor: pointer;
	text-transform: none;
	font-family: inherit;
}

h1 {
	color: var(--color-font-1);
	font-size: var(--font-size-10);
	font-weight: 800;
	letter-spacing: -2px;
}

h2 {
	color: var(--color-font-1);
	font-size: var(--font-size-7);
	font-weight: 800;
	/* letter-spacing: -1px; */
}

h3 {
	color: var(--color-font-1);
	font-size: var(--font-size-6);
	font-weight: 600;
	/* letter-spacing: -1px; */
}

h4 {
	color: var(--color-font-1);
	font-size: var(--font-size-4);
	font-weight: 800;
}

p {
	color: var(--color-font-1);
	font-size: var(--font-size-4);
	font-weight: 500;
	/* letter-spacing: -1px; */
}

hr {
	color: var(--color-font-1);
}

.pac-container {
	z-index: 9999999 !important;
}

.Toastify__toast-container {
	margin-top: 75px;
}

.Toastify__toast {
	border-radius: 10px;
	font-size: 0.9rem;
}

.Toastify__toast--success {
	background-color: rgb(99, 187, 97);
}
.Toastify__toast--warning {
	background-color: rgb(243, 179, 53);
}
.Toastify__toast--error {
	background-color: rgb(239, 89, 89);
}
.Toastify__toast--info {
	background-color: rgb(75, 182, 228);
}
.Toastify__progress-bar--success {
	background-color: rgb(142, 227, 93);
}
.Toastify__progress-bar--warning {
	background-color: rgb(251, 159, 39);
}
.Toastify__progress-bar--error {
	background-color: rgb(234, 35, 35);
}
.Toastify__progress-bar--info {
	background-color: rgb(44, 149, 194);
}

.Toastify__toast-icon {
	margin-right: 30px;
	margin-top: -4px;
}
